// Change top level case
.menuMain .topLevel li.level1>a {
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

// Useful mixin for before and after dividers
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;
}

// Add divider to carousel top/bottom
body:not(.page-no-banner) .carouselSlides {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "angle-white.svg");
        background-size: 102%;
    }
}
body.homepage .carouselSlides {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "angle-magenta.svg");
        background-size: 102%;
    }
}
.homeFeaturesWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: calc(((-78 / 1600) * 100vw) + 1px);
        background-image: url($assets-path + "angle-white.svg");
        background-size: 102%;
    }
}
.homefeaturecategory-homeboximpactstats {
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: -1px;
        background-image: url($assets-path + "angle-white.svg");
        background-size: 102%;
        transform: rotate(180deg);
    }
}
.pageFooterWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: -1px;
        background-image: url($assets-path + "angle-white.svg");
        background-size: 102%;
        transform: rotate(180deg);
    }
}

// Home intro
.homeIntroWrapper {
    padding-bottom: $spacer * 6;
}
.homeIntro {
    transform: rotate(-2deg);
}
.homeIntro p {
    letter-spacing: 0.05em;
}

// Impact
.homefeaturecategory-homeboximpactstats {
    padding-top: $spacer * 9;
}

// Home feed
.homeFeeds .homeFeed:not(:first-child) {
    padding-top: 0;
}

// Footer stuff
.footerBox.footerBox4 {
    grid-column: 1 / span 16;
    background-color: white;
    margin: $spacer * 2 0 0 0;
    padding-bottom: $spacer * 2;
    display: flex;
    justify-content: flex-start;
    padding: $spacer calc((100vw - 1240px) / 2);
    img {
        height: 60px;
        width: auto;
        margin: $spacer $spacer * 2;
    }
    @media (max-width: map-get($breakpoints, xl)) {
        flex-wrap: wrap;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        display: block;
        img {
            display: block;
            margin: $spacer auto;
        }
    }
}
.footerBox a {
    text-decoration: none !important;
    &:hover {
        color: white;
    }
}

// Remove bottom padding
.pageFooter {
    padding-bottom: 0;
}

// Mob fixes
@media (max-width: map-get($breakpoints, lg)) {
    .carouselSlideDetail {
        padding-bottom: $spacer * 3;
    }
    .homeIntroWrapper {
        padding-bottom: $spacer * 5;
    }
    .homefeaturecategory-homeboximpactstats {
        padding-top: $spacer * 7;
        padding-bottom: $spacer * 6;
    }
}
@media (max-width: map-get($breakpoints, md)) {
    .homeIntroWrapper {
        padding-bottom: $spacer * 4;
    }
}
@media (max-width: map-get($breakpoints, sm)) {
    .homeIntroWrapper {
        padding-bottom: $spacer * 3;
    }
}

// Better icon alignment in circle
.socialIcons a:before,
.shareList a:before {
    margin-top: 2px;
}

// Opening hours in menuAdmin
#menuAdmin li:last-child a {
    color: white;
    font-weight: $font-weight-bold;
    cursor: default;
    // &:before {
    //     content:"\00B7\0020";
    // }
}

// Add image background to footer
.pageFooterWrapper:before {
    content:"";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url($assets-path + 'footer.png');
    opacity: 0.2;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}
.pageFooter {
    position: relative;
}

// Add image watermark to blockquote
blockquote {
    @media (min-width: 769px) {
      padding-right: $spacer * 18;
    }

    &:after {
        content:"";
        background-image: url($assets-path + 'watermark.svg');
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-position: 103% 50%;
        background-repeat: no-repeat;
        background-size: 300px;
        opacity: 0.2;
    }
}

.eventContent blockquote:before,
.eventContent blockquote,
.appealContent blockquote:before,
.appealContent blockquote:after {
    display: initial;
}

// Remove opening hours from footer links
.footerBox {
    #menuc5fe3860-4b53-4cc8-ab41-2bccc226840b {
        display: none;
    }
}


// Inner page - bullet points
.faqAnswer ul:not(.shareList),
.listingIntro ul:not(.shareList),
.postContent ul:not(.shareList) {
  list-style: none;

  li:before {
    content: "\2022";
    color: $_blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.postContent h2, .postContent h3, .postContent h4, .postContent h5,
.postContent h5, .postContent h6 {
  color: $_blue;
  text-transform: unset;
}

.PostSystemCategory_hide-sidebar {
  .postAside {
    display: none;
  }
  .post .contentBlock .postContent {
    grid-column: 1/3;
  }
}

.GlobalAlert > * {
  max-width: $container-max-width;
  margin: 0 auto;
}

// https://raisingit.atlassian.net/browse/DES-5045
@media (min-width: 947px) {
    .feedList .feedItem {
        max-width: calc((100% / 3) - 30px);
    }
}